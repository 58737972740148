import { defaultStockQuery, useGetAllQuery } from "../services/stockApi";
import { useCallback, useMemo, useRef, useState } from "react";
import { AsyncBox } from "../components/AsyncBox";
import { DataTable } from "../components/Table";
import { Type } from "../components/Type";
import { AddEditRef, AddEditStockModal } from "../container/AddEditStockModal";
import { IStock } from "../interface";
import { DateCell } from "../components/DateCell";
import { Cell } from "react-table";
import { StocksActions } from "../container/StocksActions";
import { StockFilter } from "../container/StockFilter";
import { useAppDispatch, useAppSelector } from "../utils/store";
import { paginationSelector, selectFilters, setSortBy } from "../slice/filtersSlice";
import { Peon } from "../components/Peon";
import { Button, Flex, HStack, Text } from "@chakra-ui/react";
import { StockModal } from "../components/StockModal";
import { StockIdCell } from "../components/StockIdCell";
import Papa from "papaparse";
import { DateTime } from "luxon";
import { downloadCsvFile } from "../utils/csv";
import { DownloadIcon } from "@chakra-ui/icons";
import { useGetAllQuery as useGetAllTypesQuery } from "../services/typesApi";
import Pagination from "../components/Pagination";
import { usePagination } from "../hook/usePagination";
import client from "../utils/client";
import { ENDPOINTS } from "../config";
import { IGetStockByQuery } from "../interface/api";
import { errorToast } from "../utils/toast";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const pageNumber = (total: number, limit: number, offset: number) => {
  if (offset < 1) return 1;
  else {
    return offset / limit + 1;
  }
};

export const StocksTable = () => {
  const { t } = useTranslation();
  const filters = useAppSelector(selectFilters);
  const pagination = useAppSelector(paginationSelector);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  const { isLoading: isLoadingTypes, data: types = [], isError: isTypesError } = useGetAllTypesQuery();
  const { isLoading, data, isError, isFetching } = useGetAllQuery({
    ...filters,
    maxResults: pagination.pageSize,
    offset: pagination.offset,
  });

  const addEditRef = useRef<AddEditRef>(null);

  const memoData = useMemo(() => data?.stocks ?? [], [data]);

  const generateCsv = useCallback(async () => {
    setIsLoadingReport(true);
    try {
      const res: AxiosResponse<IGetStockByQuery> = await client.post(`${ENDPOINTS.STOCK}/getStockByQuery`, {
        ...defaultStockQuery,
        maxResults: 5000,
      });

      const dataToParse = res.data.stocks.map((stock: IStock) => {
        return {
          ...stock,
          typeId: types.find((type) => type.id === stock.typeId)?.name,
          addedBy: stock.addedBy ? `${stock.addedBy.name} ${stock.addedBy.surname}` : null,
          added: stock.added ? DateTime.fromISO(stock.added).toLocaleString({ ...DateTime.DATE_SHORT }) : null,
          removedBy: stock.removedBy ? `${stock.removedBy.name} ${stock.removedBy.name}` : null,
          removed: stock.removed ? DateTime.fromISO(stock.removed).toLocaleString({ ...DateTime.DATE_SHORT }) : null,
        };
      });
      const csvRows = Papa.unparse(dataToParse, { delimiter: ";" });
      downloadCsvFile(csvRows);
    } catch (err) {
      errorToast(t("Nie udało pobrać"));
    } finally {
      setIsLoadingReport(false);
    }
  }, [t, types]);

  return (
    <AsyncBox isError={isError || isTypesError} isLoading={isLoading || isLoadingTypes}>
      <StockFilter />
      <Flex justifyContent={"flex-end"}>
        <HStack>
          <Button
            isLoading={isLoadingReport}
            colorScheme={"teal"}
            variant="outline"
            leftIcon={<DownloadIcon />}
            onClick={generateCsv}
          >
            {t("Raport")}
          </Button>
          <StockModal />
          <AddEditStockModal button ref={addEditRef} />
        </HStack>
      </Flex>
      {isLoading || (isFetching && <Text fontSize="2xl">{`${t("Ładowanie danych")}...`}</Text>)}
      <Table data={memoData} size={data?.size ?? 0} />
    </AsyncBox>
  );
};

interface TableProps {
  data: Array<IStock>;
  size: number;
}

const Table = ({ data, size }: TableProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { offset, pageSize } = useAppSelector(paginationSelector);
  const currentPage = pageNumber(size, pageSize, offset);
  const pagination = usePagination({
    totalCount: size,
    pageSize,
    siblingCount: 3,
    currentPage,
  });

  const onChangeSort = useCallback(
    (sort: any) => {
      if (sort && sort.length > 0 && sort[0].id !== "id") {
        let sortBy = `${sort[0].id}`.toLocaleUpperCase();

        dispatch(
          setSortBy({
            sortBy,
            sortDescending: sort[0].desc,
          })
        );
      } else if (sort && sort.length > 0 && sort[0].field !== "id") {
        dispatch(setSortBy({ sortDescending: sort[0].desc }));
      } else {
        dispatch(setSortBy({ sortDescending: false }));
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: (cell: Cell<IStock>) => <StockIdCell id={cell.row.original.id} />,
      },
      {
        Header: `${t("Waga")} [kg]`,
        accessor: "weight",
      },
      {
        Header: t("Lokalizacja"),
        accessor: "location",
      },
      {
        Header: `${t("Szerokość")} [cm]`,
        accessor: "width",
      },
      {
        Header: `${t("Długość")} [m]`,
        accessor: "length",
        Cell: (cell: Cell<IStock>) => (
          <span>{Math.round((cell.row.original.length + Number.EPSILON) * 100) / 100}</span>
        ),
        disableSortBy: true,
      },
      {
        Header: t("Typ"),
        accessor: "typeId",
        Cell: (cell: Cell<IStock>) => <Type id={cell.row.original.typeId} />,
        disableSortBy: true,
      },
      {
        Header: t("Notatka"),
        accessor: "note",
        disableSortBy: true,
      },
      {
        Header: t("Utworzony"),
        accessor: "added",
        Cell: (cell: Cell<IStock>) => <DateCell dateIso={cell.row.original.added} />,
      },
      {
        Header: t("Utworzony przez"),
        accessor: "addedBy",
        Cell: (cell: Cell<IStock>) => <Peon peon={cell.row.original.addedBy} />,
        disableSortBy: true,
      },
      {
        Header: t("Usunięty"),
        accessor: "removed",
        Cell: (cell: Cell<IStock>) =>
          cell.row.original.removed ? <DateCell dateIso={cell.row.original.removed} /> : null,
      },
      {
        Header: t("Usunięty przez"),
        accessor: "removedBy",
        Cell: (cell: Cell<IStock>) => <Peon peon={cell.row.original.removedBy} />,
        disableSortBy: true,
      },
      {
        Header: t("Akcje"),
        accessor: "actions",
        Cell: (cell: Cell<IStock>) => <StocksActions stock={cell.row.original} />,
        disableSortBy: true,
      },
    ],
    [t]
  );
  if (size === 0) {
    return <Text fontSize="2xl">Brak danych</Text>;
  }

  return (
    <>
      <Pagination pages={pagination} currentPage={currentPage} size={size} />
      <DataTable data={data} columns={columns} onChangeSort={onChangeSort} />
      <Pagination pages={pagination} currentPage={currentPage} size={size} />
    </>
  );
};
